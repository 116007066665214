<template>
  <div class="dashboard-login">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
      <div class="hero-title">
        <h3 class="title is-3 has-text-centered pt-6 txt-white">
          Inicia sesión o registrate para seguir
        </h3>
      </div>
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <div class="my-account-wrap">
          <div class="columns content-my-account-wrap">
            <div class="column is-5 is-offset-1 login-wrap">
              <h4 class="title is-3 has-text-centered pb-5">Inicia sesión</h4>
                <form class="overflow-h" onsubmit="return false">
                  <div class="field mb-5">
                    <input-text :placeholder="'Email*'" v-model="email" :name="'email'"></input-text>
                  </div>
                  <div class="field mb-5">
                    <input-text :placeholder="'Contraseña*'" v-model="password" :name="'password'" :type="'password'"></input-text>
                  </div>
                  <div class="custom-switch">
                    <input type="checkbox" id="switch" v-model="rememberMe"/><label class="toggle" for="switch">Toggle</label>
                    <label class="switch-label">
                      Recuérdame
                    </label>
                  </div>
                  <router-link class="recover-pwd" :to="'/laboratories/recover-password'" exact>Recupera la contraseña</router-link>
                  <div class="field">
                    <p class="control">
                      <button class="button is-primary is-fullwidth is-medium mt-4" @click="login()">
                        Iniciar sesión
                      </button>
                    </p>
                  </div>
                </form>
            </div>
            <div class="column is-5 register-wrap">
              <h4 class="title is-3 has-text-centered pb-3">¿Porque unirse a nosotros?</h4>
              <p class="is-size-6 pb-4 has-text-centered">
                Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Donec id elit non mi porta gravida at eget metus. Donec sed odio dui. Maecenas faucibus mollis interdum.
              </p>
              <button class="button is-primary is-fullwidth is-medium mt-4" @click="goTo('/clinic/personal-informations')">
                Enviar una solicitud
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeroImage from '@/components/HeroImage.vue'
import Footer from '@/components/Footer.vue'
import InputText from '@/components/FormElements/InputText.vue'
import { IsEmptyOrNull } from '@/utils/utils'

export default {
  name: 'LaboratoryLogin',
  components: {
    HeroImage,
    Footer,
    InputText
  },
  data () {
    return {
      email: '',
      password: '',
      rememberMe: false
    }
  },
  methods: {
    login () {
      const self = this
      if (this.rememberMe) {
        localStorage.setItem('remember-email', this.email)
      } else {
        localStorage.removeItem('remember-email')
      }
      this.$store.dispatch('user/logIn', {
        credentials: {
          email: this.email,
          password: this.password
        },
        cb: (response) => {
          console.log(response)
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'success',
              message: 'Bienvenido!'
            }
          })
          self.$store.dispatch('user/getUser', {
            cb: () => {
              self.goTo('/laboratory/info-laboratory')
            },
            cbError: (error) => {
              console.log(error)
              self.$store.commit({
                type: 'application/ADD_FEEDBACK',
                feedback: {
                  type: 'error',
                  message: error.response.data.error
                }
              })
            }
          })
        },
        cbError: (error) => {
          if (IsEmptyOrNull(error.response)) {
            self.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'alert',
                message: 'Error message'
              }
            })
          } else {
            self.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'error',
                message: error.response.data.error
              }
            })
          }
        }
      })
    },
    goTo (path) {
      this.$router.push({ path: path })
    }
  },
  created () {
    const emailSaved = localStorage.getItem('remember-email')
    if (emailSaved) {
      this.email = emailSaved
      this.rememberMe = true
    }
  }
}
</script>

<style scoped lang="scss">
.dashboard-login{
  width: 100%;
  section{
    width: 100%;
    padding: 0px 0px;
    &.over-hero{
      position: relative;
      margin-top: -340px;
      margin-bottom: 80px;
      z-index: 100;
    }
    .my-account-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-my-account-wrap{
        padding: 60px;
        .login-wrap, .register-wrap {
          padding: 0px 60px;
          padding-top: 24px;
        }
        .login-wrap{
          border-right: 1px solid #57567C;
          form{
            .recover-pwd{
              float: right;
              text-decoration: underline;
            }
            button{
              float: left;
              margin-top: 48px;
            }
          }
        }
        .register-wrap {
          position: relative;
          button{
            max-width: 354px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .dashboard-login{
    section{
      .my-account-wrap{
        .content-my-account-wrap{
          padding: 35px;
          .login-wrap{
            border-right: 0px;
            overflow: hidden;
            form{
              button{
                display: block;
                line-height: 0px;
              }
            }
          }
          .register-wrap{
            overflow: hidden;
            button{
              position: initial;
              transform: initial;
              max-width: initial;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .dashboard-login{
    section{
      .my-account-wrap{
        .content-my-account-wrap{
          .login-wrap{
            padding: 0 14px;
          }
          .register-wrap{
            margin-top: 40px;
            border-top: 1px solid #57567C;
            padding: 0 14px;
            padding-top: 25px;
          }
        }
      }
    }
  }
}
</style>
